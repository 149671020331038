@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
}
@media screen and (max-width: 767.98px) {
  .container {
    max-width: 430px;
  }
}
@media screen and (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media screen and (min-width: 1200px) {
  .container {
    padding: 0 40px;
    max-width: 1200px;
  }
}

.is-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: currentColor;
  display: block;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.link {
  text-decoration: none;
}

.list {
  text-decoration: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

svg {
  fill: currentColor;
}

html,
body {
  font-family: "Manrope", sans-serif;
  background-color: #FFFFFF;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  color: #060F13;
  margin: 0 auto;
  width: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body.hidden {
  overflow: hidden;
}

li:last-child {
  margin-bottom: 0 !important;
}

address {
  font-style: normal;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

section {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media screen and (min-width: 1200px) {
  section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-weight: 800;
  font-size: 18px;
  line-height: 1.2;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  color: #d90d0d;
}
.logo:hover, .logo:focus {
  color: #800000;
}
.logo:active {
  color: inherit;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  border: 1px solid transparent;
  border-radius: 99px;
  padding: 8px 14px;
  font-weight: 500;
  font-size: 16px;
  color: inherit;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  background-color: transparent;
}
.link:hover, .link:focus {
  color: #d90d0d;
  border: 1px solid #d90d0d;
}
.link:active {
  color: inherit;
  border: 1px solid #800000;
}
.link.active {
  border: 1px solid #d90d0d;
}

.btn {
  width: 100%;
  max-width: max-content;
  border: 1px solid #d90d0d;
  border-radius: 5px;
  padding: 8px 20px;
  background-color: #d90d0d;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  text-transform: uppercase;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
}

.hfa {
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}
.hfa:hover, .hfa:focus {
  background-color: #800000;
}
.hfa:active {
  background-color: #d90d0d;
}

.just-validate-error-field.form-input {
  border: 2px solid #f27508;
}

.just-validate-error-label {
  display: none;
}

.header {
  padding-top: 24px;
  padding-bottom: 24px;
}
@media screen and (min-width: 1200px) {
  .header {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.header .navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
@media screen and (min-width: 1200px) {
  .header .navigation {
    justify-content: space-between;
  }
}
.header-nav {
  display: none;
}
@media screen and (min-width: 1200px) {
  .header-nav {
    display: flex;
    gap: 2px;
    align-items: center;
    justify-content: flex-end;
  }
}

.footer {
  padding: 24px 0;
  background: #f0f0f0;
}
@media screen and (min-width: 768px) {
  .footer .navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.footer .list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-top: 24px;
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
  .footer .list {
    flex-direction: row;
    align-items: center;
  }
}
.footer .lower {
  padding-top: 24px;
  border-top: 2px solid #d90d0d;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 32px;
}
@media screen and (min-width: 1200px) {
  .footer .lower {
    flex-direction: row;
    align-items: center;
  }
}
.footer .lower p {
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
}
.footer .social-list {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}
.footer .social-list .link {
  padding: 0;
}

/* fix html, body */
.hystmodal__opened {
  position: fixed;
  right: 0;
  left: 0;
  overflow: hidden;
}

/* modal backdrop */
.hystmodal__shadow {
  position: fixed;
  border: none;
  display: block;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  z-index: 98;
  opacity: 0;
  transition: opacity 0.15s ease;
  background-color: black;
}

/* backdrop при відкривтому модальному вікні */
.hystmodal__shadow--show {
  pointer-events: auto;
  opacity: 0.6;
}

/*  backdrop що огортає враппер модалки */
.hystmodal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  opacity: 1;
  pointer-events: none;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  z-index: 99;
  visibility: hidden;
}

/*  показати backdrop що огортає враппер модалки */
.hystmodal--active {
  pointer-events: auto;
  opacity: 1;
}
.hystmodal--active .hystmodal__window {
  opacity: 1;
}

.hystmodal--moved {
  pointer-events: auto;
  opacity: 1;
}

.hystmodal__wrap {
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  min-height: 100%;
  margin: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.hystmodal__window {
  position: relative;
  margin: 50px 0;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-grow: 0;
  background: #fff;
  width: 600px;
  max-width: 100%;
  overflow: visible;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

.hystmodal__close {
  position: absolute;
  z-index: 10;
  top: 20px;
  right: 20px;
  display: block;
  width: 30px;
  height: 30px;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' stroke='%23fff' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M22 2L2 22'/%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M2 2l20 20'/%3E%3C/svg%3E");
  background-size: 100% 100%;
  border: none;
  font-size: 0;
  cursor: pointer;
  outline: none;
  transition: color 300ms ease-in-out;
}
.hystmodal__close svg {
  fill: currentColor;
}
.hystmodal__close:hover {
  color: red;
}
.hystmodal__close:focus {
  color: red;
  outline: 2px dotted #afb3b9;
  outline-offset: 2px;
}
.hystmodal__close:active {
  color: inherit;
}

/* ------------ pop-up */
.hystmodal.hystmodal--cookie .hystmodal__wrap {
  justify-content: flex-end;
}
.hystmodal.hystmodal--cookie .hystmodal__window {
  background-color: transparent;
  width: 100%;
  margin-bottom: 0;
  color: #FFFFFF;
}
.hystmodal.hystmodal--cookie .hystmodal__window .hystmodal__close {
  display: none;
}
.hystmodal.hystmodal--cookie .hystmodal__window .modal-content {
  color: #060F13;
  overflow: hidden;
  box-shadow: 0 0 1px 0 rgba(96, 96, 96, 0.1), 0 12px 24px -6px rgba(96, 96, 96, 0.15);
  background: #fff;
  border-radius: 20px;
  padding: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}
@media screen and (min-width: 1200px) {
  .hystmodal.hystmodal--cookie .hystmodal__window .modal-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1120px;
    margin: 0 auto;
  }
}
.hystmodal.hystmodal--cookie .hystmodal__window .text-wrapper {
  max-width: 720px;
}
.hystmodal.hystmodal--cookie .hystmodal__window .subtitle {
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 162%;
}
.hystmodal.hystmodal--cookie .hystmodal__window .btn-wrapper {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}
@media screen and (min-width: 1200px) {
  .hystmodal.hystmodal--cookie .hystmodal__window .btn-wrapper {
    margin-top: 0;
  }
}
.hystmodal.hystmodal--cookie .hystmodal__window .btn {
  max-width: 100%;
  background: #d90d0d;
  border-radius: 43px;
  padding: 6px 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 157%;
  text-transform: uppercase;
  text-align: center;
  color: #FFFFFF;
}

/* ------------ burger */
.hystmodal.hystmodal--burger .hystmodal__wrap {
  justify-content: flex-start;
}
.hystmodal.hystmodal--burger .hystmodal__window {
  margin: 0;
  width: 100%;
  height: 100%;
  color: black;
  padding: 50px;
}

.hero {
  padding-top: 80px;
}
.hero .title {
  font-weight: 800;
  font-size: 48px;
  line-height: 124%;
}
.hero .subtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  max-width: 600px;
  margin-top: 18px;
  margin-bottom: 32px;
}
.hero img {
  width: 100%;
  max-width: 1120px;
}

.people .list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}
@media screen and (min-width: 768px) {
  .people .list {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
  }
}
.people .list__item {
  border-radius: 16px;
  padding: 16px 16px 20px 16px;
  background: rgba(217, 217, 217, 0.4);
}
@media screen and (min-width: 768px) {
  .people .list__item {
    max-width: 352px;
  }
}
@media screen and (min-width: 1200px) {
  .people .list__item {
    max-width: 552px;
  }
}
.people .list__item .upper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
}
.people .list__item-logo {
  display: flex;
  gap: 5px;
  color: #d90d0d;
  border: 1px solid #d90d0d;
  border-radius: 99px;
  padding: 6px 16px;
}
.people .list__item-title {
  font-weight: 800;
  font-size: 24px;
  line-height: 124%;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.podcast {
  padding-top: 180px;
  padding-bottom: 40px;
  color: #FFFFFF;
}
.podcast.bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../img/6.webp");
}
.podcast .content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
}
@media screen and (min-width: 768px) {
  .podcast .content-wrapper {
    flex-direction: row;
    gap: 150px;
  }
}
.podcast .descr {
  font-weight: 500;
  text-transform: uppercase;
  width: 100%;
  max-width: 130px;
}
.podcast .title {
  font-weight: 800;
  font-size: 46px;
  line-height: 124%;
  margin-bottom: 16px;
}
.podcast .subtitle {
  font-weight: 500;
}

.dreams .content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
}
@media screen and (min-width: 768px) {
  .dreams .content-wrapper {
    flex-direction: row;
    gap: 150px;
  }
}
.dreams .descr {
  font-weight: 500;
  text-transform: uppercase;
  width: 100%;
  max-width: 135px;
}
.dreams .title {
  font-weight: 700;
  font-size: 46px;
  line-height: 124%;
  margin-bottom: 16px;
}
.dreams .subtitle {
  font-weight: 500;
}

.join .content-wrapper {
  border-radius: 16px;
  padding: 20px;
  background: rgba(217, 217, 217, 0.4);
}
.join img {
  width: 100%;
}
.join .title {
  margin-top: 60px;
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 24px;
  line-height: 124%;
}
.join .subtitle {
  font-weight: 500;
  line-height: 150%;
}

@media screen and (min-width: 1200px) {
  .contacts {
    padding-top: 0;
    padding-bottom: 50px;
  }
}
.contacts .content-wrapper {
  min-height: calc(100vh - 371px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
@media screen and (min-width: 1200px) {
  .contacts .content-wrapper {
    align-items: flex-start;
  }
}
.contacts .title {
  font-weight: 700;
  font-size: 48px;
  line-height: 124%;
  margin-bottom: 32px;
}
.contacts .image {
  width: 100%;
  margin-bottom: 100px;
}

.success .content-wrapper {
  min-height: calc(100vh - 501px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 72px;
}
@media screen and (min-width: 768px) {
  .success .content-wrapper {
    min-height: calc(100vh - 372px);
  }
}
@media screen and (min-width: 1200px) {
  .success .content-wrapper {
    min-height: calc(100vh - 458px);
  }
}
.success .title {
  font-weight: 500;
  font-size: 32px;
  line-height: 150%;
  text-transform: capitalize;
  text-align: center;
}
@media screen and (min-width: 1200px) {
  .success .title {
    font-size: 60px;
  }
}
.success .subtitle {
  font-weight: 700;
  font-size: 20px;
}
.success .link {
  text-decoration: underline;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.form-label {
  width: 100%;
}
.form-input {
  width: 100%;
  background: #f0f0f0;
  border: 2px solid transparent;
  border-radius: 4px;
  font-family: inherit;
  padding: 12px 30px;
  font-size: 15px;
  line-height: 150%;
  text-transform: capitalize;
  color: #060F13;
  min-width: 105px;
}
.form-input.textarea {
  min-height: 150px;
  resize: none;
  position: relative;
}
.form .btn {
  min-width: 160px;
  margin: 0 auto;
}
@media screen and (min-width: 1200px) {
  .form .btn {
    margin-right: 0;
    margin-left: auto;
  }
}

.privacy .content-wrapper {
  max-width: 820px;
}
.privacy h1 {
  font-weight: 700;
  font-size: 48px;
}
.privacy .disc {
  list-style: disc;
  padding-left: 20px;
}