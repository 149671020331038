.header{
    padding-top: 24px;
    padding-bottom: 24px;

    @include desktop{
        padding-top: 30px;
        padding-bottom:30px;
    }

    .navigation{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        @include desktop{
            justify-content: space-between;
        }
        
    }
    &-nav{
        display: none;

        @include desktop{
            display: flex;
            gap: 2px;
            align-items: center;
            justify-content: flex-end;
        }
    }
}


