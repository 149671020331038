// font family
$main-font: "Manrope", sans-serif;


// bg
$main-bg:#FFFFFF;

// text

// transition
$transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

$white: #FFFFFF;
$black:#060F13;
$accent:#d90d0d;
$second:#800000;
$error:#f27508;

// modal
$modal-bgd: #2C2C2C;
$burger-btn-stroke: #292D32;


