.hero{

    padding-top:80px;
    .title{
        font-weight: 800;
        font-size: 48px;
        line-height: 124%;
    }

    .subtitle{
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        max-width: 600px;
        margin-top: 18px;
        margin-bottom: 32px;
    }
    
    img{
        width: 100%;
        max-width: 1120px;
    }
}

.people{
    .list{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        @include tablet {
            flex-direction: row;
            flex-wrap: wrap;
            align-items: stretch;
        }

        &__item{
            border-radius: 16px;
            padding: 16px 16px 20px 16px;
            background: rgba(217, 217, 217, 0.4);

            @include tablet{
                max-width: 352px;
            }
            @include desktop{
                max-width: 552px;
            }

            .upper{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                margin-bottom: 40px;
            }

            &-logo{
                display: flex;
                gap: 5px;
                color: $accent;
                border: 1px solid #d90d0d;
                border-radius: 99px;
                padding: 6px 16px;
            }

            &-title{
                font-weight: 800;
                font-size: 24px;
                line-height: 124%;
                text-transform: capitalize;
                margin-bottom: 20px;
            }

        }
    }
}

.podcast{
    padding-top: 180px;
    padding-bottom: 40px;
    color: $white;
    &.bg{
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('../img/6.webp');
    }

    .content-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        gap: 40px;

        @include tablet{
            flex-direction: row;
            gap: 150px;

        }
    }

    .descr{
        font-weight: 500;
        text-transform: uppercase;
        width: 100%;
        max-width: 130px;
    }

    .title{
        font-weight: 800;
        font-size: 46px;
        line-height: 124%;
        margin-bottom: 16px;
    }
    .subtitle{
        font-weight: 500;
    }

}

.dreams{
    .content-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        gap: 40px;

        @include tablet{
            flex-direction: row;
            gap: 150px;

        }
    }

    .descr{
        font-weight: 500;
        text-transform: uppercase;
        width: 100%;
        max-width: 135px;
    }

    .title{
        font-weight: 700;
        font-size: 46px;
        line-height: 124%;
        margin-bottom: 16px;
    }
    .subtitle{
        font-weight: 500;
    }

}


.join{

    .content-wrapper{
        border-radius: 16px;
        padding: 20px;
        background: rgba(217, 217, 217, 0.4);

    }

    img{
        width: 100%;
    }
    .title{
        margin-top: 60px;
        margin-bottom: 16px;
        font-weight: 700;
        font-size: 24px;
        line-height: 124%;
    }

    .subtitle{
        font-weight: 500;
        line-height: 150%;
    }
}




.contacts{

    @include desktop{
        padding-top: 0;
        padding-bottom: 50px;
    }

        .content-wrapper {
            min-height: calc(100vh - 371px); //710px height = header + section padding + footer
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            @include desktop{
                align-items: flex-start;
            }

        }

        .title{
            font-weight: 700;
            font-size: 48px;
            line-height: 124%;
            margin-bottom: 32px;
        }

        .image{
            width: 100%;
            margin-bottom: 100px;
        }


}

// =================== success page
.success{

    .content-wrapper {
        min-height: calc(100vh - 501px); //710px height = header + section padding + footer
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 72px;

            @include tablet{
                min-height: calc(100vh - 372px); // height = header + section padding + footer
            }

            @include desktop {
                min-height: calc(100vh - 458px); // height = header + section padding + footer
                
            }
        }

        .title{
font-weight: 500;
    font-size: 32px;
    line-height: 150%;
    text-transform: capitalize;
    text-align: center;
    @include desktop{
        font-size: 60px;
    }
        }
        .subtitle{
            font-weight: 700;
                font-size: 20px;

        }
                .link{
                    text-decoration: underline;
                }
}