*{
    box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-top: 0;
    margin-bottom: 0;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

a {
    text-decoration: none;
    color: currentColor;
    display: block;
}

ul,ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

.link {
    text-decoration: none;
}

.list {
    text-decoration: none;
    list-style: none;
    margin: 0;
    padding: 0;
}

svg{
    fill: currentColor;
}
