.privacy{
    .content-wrapper{
        max-width: 820px;
    }
    h1{
        font-weight: 700;
        font-size: 48px;

    }
        .disc{
            list-style: disc;
            padding-left: 20px;
        }

}