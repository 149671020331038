.footer{
    padding: 24px 0;
    background: #f0f0f0;

    .navigation{
        @include tablet{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .list{
        display:  flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        margin-top: 24px;
        margin-bottom: 30px;
        @include tablet{
            flex-direction: row;
            align-items: center;
        }
    }

    .lower{
        padding-top: 24px;
        border-top: 2px solid $accent;

        display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            gap: 32px;

            @include desktop {
                    flex-direction: row;
                    align-items: center;

                }

                p{
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 150%;
                }
    }

    .social-list{
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;

        .link{
            padding: 0;
        }
    }
}
    

