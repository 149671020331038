.form{
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;


    &-label{
        width: 100%;
    }

    &-input{
        width: 100%;
        background: #f0f0f0;
        border: 2px solid transparent;
        border-radius: 4px;
        font-family: inherit;
        padding: 12px 30px;
        font-size: 15px;
        line-height: 150%;
        
        text-transform: capitalize;
        color: $black;
        min-width: 105px;

        &::placeholder{
        }

        &.textarea {
            min-height: 150px;
            resize: none;
            position: relative;

        //     &::placeholder{
        //         position: absolute;
        //         bottom: 17px;
        //         left: 30px;
        //     }
        }
    }

    .btn{
        min-width: 160px;
        margin: 0 auto;
        @include desktop{
            margin-right: 0;
            margin-left: auto;
        }
       
    }


}